
export function decapitalizeString(string){
    const words = string.split(' '); 
    const decapitalizedWords = words.map(word => word.charAt(0).toLowerCase() + word.slice(1)); 
    const decapitalizedString = decapitalizedWords.join(' '); 
    return decapitalizedString
  }

  export function safeJSONParse(data){
    try {
  
      if( typeof data === 'object' && data != null){
        return data
      }
      console.log(typeof data)
  
      return JSON.parse(data)
    }catch (error){
      return data
    }
  }
  
  export const msToTime = (ms, label, long) => {
    const currentDate = new Date();
    const date2 = new Date(currentDate.getTime() - ms);
    const milliSeconds = currentDate.getTime() - date2.getTime();
    let seconds = (milliSeconds / 1000).toFixed(0);
    let minutes = (milliSeconds / (1000 * 60)).toFixed(0);
    let hours = (milliSeconds / (1000 * 60 * 60)).toFixed(0);
    let days = (milliSeconds / (1000 * 60 * 60 * 24)).toFixed(0);
    if (seconds < '1') return 'Just Now';
    else if (seconds < '60') return `${seconds} ${long === 'long' ? 'Seconds' : 'Sec'} ${label ? label : ''}`;
    else if (minutes < '60') return `${minutes} ${long === 'long' ? 'Minutes' : 'Min'} ${label ? label : ''}`;
    else if (hours < '24') return `${hours} ${long === 'long' ? 'Hours' : 'Hrs'} ${label ? label : ''}`;
    else return `${days} Days ${label ? label : ''}`;
  };
  
  export const getKey = function getKey() {
    var url = window?.location?.href ?? null;
    var urlObject = new URL(url);
    var kValue = urlObject.searchParams.get('k');
    return kValue ? kValue : null;
};

export const getEmbedType = () => {
    var url = window?.location?.href ?? null;
    var urlObject = new URL(url);
    var type = urlObject.searchParams.get('type');
    return type ? type.toUpperCase() : "SEARCH";
};

function sanitizeForDecodeURI(input) {
  // Replace any '%' not followed by two hexadecimal digits with '%25'
  // Replace '/' with '%2F'
  return input.replace(/%(?![0-9A-Fa-f]{2})/g, '%25').replace(/\//g, '%2F');
}

export const getANSData = () => {
  const url = window?.location?.href ?? null;
  if (!url) return null;
  console.log('url', url);

  const urlObject = new URL(url);
  let pValue = urlObject?.searchParams?.get('p');

  // Attempt decoding with a try-catch
  try {
      pValue = decodeURIComponent(pValue);
  } catch (e) {
      console.error("Decoding error: ", e);
  }
  
  return pValue ? pValue : null;
}

export const sendMessage = function sendMessage(action, data) {
    if (window.parent) {
        var messagePayload = {
            source: 'custom_embed',
            action: action,
            data: data,
            key: getKey()
        };

        if (action === 'ready') {
            console.log('ready')
            messagePayload.isAnsRequired = true;
        }
        window.parent.postMessage(JSON.stringify(messagePayload), '*');
    }
};
